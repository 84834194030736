import React, { Component } from 'react';

import Moment from 'react-moment/dist/index';

class NewColleagueNewsView extends Component{
    render(){
        let location = undefined;
        switch (this.props.newColleagueNews.employee.location) {
            case 'SAARBRUECKEN':
                location = "Saarbrücken";
                break;
            case 'SCHEIDT':
                location = "Scheidt";
                break;
            case 'LEIPZIG':
                location = "Leipzig";
                break;
            default: break;
        }
        return(
            <div id="new-colleague-view" className="news" style={this.props.newColleagueNews.backgroundColor ? {backgroundColor: this.props.newColleagueNews.backgroundColor} : {}}>
                <div className="content">
                    <h1>Neuer Mitarbeiter!<i>Herzlich Willkommen!</i></h1>
                    <div className="row" id="colleague-details">
                        <div className="col-12">
                            <h1>{this.props.newColleagueNews.employee.firstName} {this.props.newColleagueNews.employee.lastName}</h1>
                        </div>
                        <div className="col-6">
                            <ul>
                                <li className="location">{location}</li>
                                <li className="begin"><Moment format="DD.MM.YYYY" date={this.props.newColleagueNews.begin}/></li>
                                <li className="job">{this.props.newColleagueNews.employee.jobTitle}</li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <img id="team-emblem" src={'api/team/image/' + this.props.newColleagueNews.team.logo} width="80" alt={this.props.newColleagueNews.team.teamLabel} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewColleagueNewsView

import {parseISO} from "date-fns";
import React, {Component} from 'react';

import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem, OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import { enqueueSnackbar } from 'notistack'
import {withRouter} from "../../common/withRouter";

import DefaultNews from './DefaultNews'
import GraduationNews from './GraduationNews'
import GreetingNews from './GreetingNews'
import NewColleagueNews from './NewColleagueNews'
import ProjectCompletionNews from './ProjectCompletionNews'
import AppointmentNews from './AppointmentNews'
import RapidTechTalkNews from "./RapidTechTalkNews";
import axios from "axios";
import TeamTripNews from "./TeamTripNews";

class NewsUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedAll: false,
            newsTypes: [
                {value: 'DEFAULT', label: 'Standard'},
                {value: 'GRADUATION', label: 'Abschluss'},
                {value: 'GREETING', label: 'Begrüßung'},
                {value: 'NEW_COLLEAGUE', label: 'Neuer Kollege'},
                {value: 'PROJECT_COMPLETION', label: 'Projektabschluss'},
                {value: 'RAPID_TECH_TALK', label: 'Rapid tech talk'},
                {value: 'TEAM_TRIP', label: 'Teamausflüge'},
                {value: 'APPOINTMENT', label: 'Termin'},
            ],
            selectedNewsType: '',
            locations: this.props.locations,
            teams: [],
            employees: [],
            genders: [
                {value: 'men', label: 'Men'},
                {value: 'women', label: 'Woman'},
                {value: 'divers', label: 'Divers'}
            ],
            news: {
                locations: [this.props.locationLabel],
                validFrom: new Date(),
                validTo: new Date()
            }
        };

        this.handleNewsTypeSelection = this.handleNewsTypeSelection.bind(this);
        this.handleLocationSelection = this.handleLocationSelection.bind(this);
        this.handleValidFromChange = this.handleValidFromChange.bind(this);
        this.handleValidToChange = this.handleValidToChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.router.params.newsId) {
            this.getNews(this.props.router.params.newsId);
        }
    }

    getNews(id) {
        axios.get('/api/news/'+id).then(res => {
            const news = res.data;
            if (news.team && news.team.id) {
                news.teamId = news.team.id;
            }
            this.setState(prevState => ({
                selectedNewsType: news.newsType,
                news: {
                    ...news,
                    date: news.date ? parseISO(news.date) : null,
                    graduationDate: news.graduationDate ? parseISO(news.graduationDate) : null,
                    begin: news.begin ? parseISO(news.begin) : null,
                    validFrom: parseISO(news.validFrom),
                    validTo: parseISO(news.validTo)
                },
            }));
        });
    }

    handleNewsTypeSelection(selection) {
        this.setState(prevState => ({
            selectedNewsType: selection.target.value
        }));
    }

    handleLocationSelection(selection) {
        let locations = [];
        if (selection.target.value.includes("all")) {
            for(var location of this.state.locations) {
                locations.push(location.value);
            }
        } else {
            locations = selection.target.value;
        }
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                locations: locations
            }
        }));
    }

    handleValidFromChange(validFrom) {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                validFrom: validFrom
            }
        }));
    }

    handleValidToChange(validTo) {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                validTo: validTo
            }
        }));
    }

    handleSubmit(news) {
        const me = this;

        let backroundImage;
        if (news.background) {
            backroundImage = news.background;
            news.background = undefined;
        }
        let logoImage;
        if (news.logo) {
            logoImage = news.logo;
            news.logo = undefined;
        }

        axios.post('/api/news', news).then(function (response) {
            if (backroundImage || logoImage) {
                if (backroundImage) {
                    me.imageUpload(response.data.id, 'IMAGE', backroundImage);
                }
                if (logoImage) {
                    me.imageUpload(response.data.id, 'LOGO', logoImage);
                }
            } else {
                enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
                window.location = '/admin/news';
            }
        }).catch(function (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            console.error(error);
        });
    }

    imageUpload(newsId, imageType, image) {
        const me = this;
        axios.postForm('/api/news/'+ newsId + '/image/' + imageType, image).then(function (team) {
            enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
            window.location = '/admin/news';
        }).catch(function (error) {
            enqueueSnackbar(error, { variant: 'error' });
            console.error(error);
        });
    }

    translateLocations(locations){
        const locationsTranslated = [];
        locations.forEach(function(locationLabel){
        switch (locationLabel) {
            case 'SCHEIDT':
                locationsTranslated.push('Scheidt');
                break;
            case 'SAARBRUECKEN':
                locationsTranslated.push('Saarbrücken');
                break;
            case 'LEIPZIG':
                locationsTranslated.push('Leipzig');
                break;
            default:
                locationsTranslated.push('');
                break;
          }
        });
        return locationsTranslated;
    }

    translateLocation(locationLabel){
        switch (locationLabel) {
            case 'SCHEIDT':
              return 'Scheidt';
            case 'SAARBRUECKEN':
              return 'Saarbrücken';
            case 'LEIPZIG':
                return 'Leipzig';
            default:
                return '';
          }
    }

    render() {
        return (
            <div id="news-upload">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="newsType">Typ</InputLabel>
                            <Select
                                defaultValue=""
                                value={this.state.selectedNewsType}
                                onChange={this.handleNewsTypeSelection}
                                inputProps={{
                                    name: 'newsType',
                                    id: 'newsType',
                                }}
                                readOnly={!!this.state.news.id}
                                input={ <OutlinedInput label={'Typ'} notched /> }>
                                {this.state.newsTypes.map((newsType) => <MenuItem value={newsType.value} key={newsType.value}>{newsType.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="location">Standort</InputLabel>
                            <Select
                                defaultValue=""
                                multiple
                                value={this.state.news.locations}
                                onChange={this.handleLocationSelection}
                                renderValue={selected => this.translateLocations(this.state.news.locations).join(', ')}
                                input={ <OutlinedInput label={'Standort'} notched /> }>
                                <MenuItem value="all" key="all">Alle auswählen</MenuItem>
                                {this.state.locations?.map((location) =>
                                    <MenuItem value={location} key={location}>
                                        <Checkbox checked={this.state.news.locations.indexOf(location) > -1}/>
                                        <ListItemText primary={this.translateLocation(location)}/>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            label="Gültig ab"
                            value={this.state.news.validFrom}
                            minDate={new Date()}
                            onChange={date => this.handleValidFromChange(date)}
                            autoOk
                            showTodayButton
                            disableToolbar
                            fullWidth
                            required/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            label="Gültig bis"
                            value={this.state.news.validTo}
                            minDate={this.state.news.validFrom}
                            onChange={date => this.handleValidToChange(date)}
                            autoOk
                            showTodayButton
                            disableToolbar
                            fullWidth
                            required/>
                    </Grid>
                </Grid>
                <hr/>
                {(() => {
                    if (this.state.selectedNewsType && this.state.news.locations.length > 0 && this.state.news.validFrom && this.state.news.validTo) {
                        switch (this.state.selectedNewsType) {
                            case 'DEFAULT':
                                return <DefaultNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'GRADUATION':
                                return <GraduationNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'GREETING':
                                return <GreetingNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'NEW_COLLEAGUE':
                                return <NewColleagueNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'PROJECT_COMPLETION':
                                return <ProjectCompletionNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'RAPID_TECH_TALK':
                                return <RapidTechTalkNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'APPOINTMENT':
                                return <AppointmentNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            case 'TEAM_TRIP':
                                return <TeamTripNews news={this.state.news} onSubmit={this.handleSubmit}/>;
                            default:
                                return '';
                        }
                    }
                })()}
            </div>
        );
    }
}

export default withRouter(NewsUpload);

import React, {Component} from 'react';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import {Button, Grid, IconButton, InputAdornment, TextField} from '@mui/material';
import {CloudUpload} from '@mui/icons-material';
import CharacterCounter from "../../common/CharacterCounter";

import { TwitterPicker } from 'react-color'

class DefaultNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: this.props.news,
            background: {
                color: '#3B3B3C',
                src: '',
                name: ''
            }
        };

        this.onImgUpload = this.onImgUpload.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType
                }
            }));
        }
    }

    backgroundInput() {
        document.getElementById('backgroundInput').click();
    }

    onImgUpload(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({background: {name: files[0].name, src: reader.result}});
        };
        reader.readAsDataURL(files[0]);
    }

    cropImage() {
        return new Promise((resolve, reject) => {
            if (this.state.background.src) {
                if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
                    reject(undefined)
                }
                this.cropper.getCroppedCanvas().toBlob(function(blob) {
                    let formData = new FormData();
                    formData.append("image", blob, "image.png");
                    resolve(formData)
                });
            } else {
                resolve(undefined)
            }
        })
    }

    handleNewsChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                [key]: value
            }
        }));
    }

    handleChangeBackgroundColor = (backgroundColor) => {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                backgroundColor: backgroundColor.hex
            }
        }));
    }

    checkData() {
        return (!this.state.background.src && !this.state.news.backgroundSrc)
    }

    handleSubmit(event) {
        let news = this.state.news;
        news.comment = news.title ? news.title : this.state.background.name;
        news.newsType = 'DEFAULT';
        this.cropImage().then((formData) => {
            news.background = formData;
            this.props.onSubmit(news);
        }).catch((error) => {
            console.error('cropImage', error);
            this.props.onSubmit(news);
        });
    }

    render() {
        let backgroundColor = this.state.news.backgroundColor ? this.state.news.backgroundColor : "#3B3B3C";
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        label="Titel"
                        value={this.state.news.title}
                        onChange={this.handleNewsChange('title')}
                        onBlur={this.cropImage}
                        required={!!this.state.news.description}
                        inputProps={{ maxLength: 25 }}
                        fullWidth
                    />
                    <CharacterCounter value={this.state.news.title} maxLength={25}></CharacterCounter>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Beschreibung"
                        value={this.state.news.description}
                        onChange={this.handleNewsChange('description')}
                        onBlur={this.cropImage}
                        fullWidth
                        multiline
                        minRows={4}
                        inputProps={{ maxLength: 255 }}
                    />
                    <CharacterCounter value={this.state.news.description} maxLength={255}></CharacterCounter>
                </Grid>

                <Grid item xs={12}>
                    <input id="backgroundInput" hidden type="file" onChange={this.onImgUpload}/>
                    <TextField
                        label="Bild"
                        onClick={this.backgroundInput.bind(this)}
                        value={this.state.background.name}
                        fullWidth
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CloudUpload/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Cropper
                        src={this.state.background.src}
                        style={{height: 216, width: '100%'}}
                        aspectRatio={1440 / 1080} // 4:3
                        // viewMode={2}
                        guides={false}
                        preview=".img-preview"
                        onInitialized={cropper => this.cropper = cropper}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className="img-preview-container" style={{backgroundColor: backgroundColor}}>
                        <div className="preview16to9"></div>
                        <div className="img-preview">
                            {this.state.news.backgroundSrc ? <div className="cropper-img"><img src={'/api/news/image/' + this.state.news.backgroundSrc} alt='cropped'/></div> : ''}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TwitterPicker
                        color={ backgroundColor}
                        onChangeComplete={ this.handleChangeBackgroundColor }
                        width="100%"
                        triangle="top-right"
                        colors={['#5CB8B2', '#008580','#BBD6B5', '#F8C135', '#FA7800', '#BD4B11','#3C3C3B', '#F3F3F3', '#FFF']}
                    />
                </Grid>

                <Grid item xs={12} className='text-right'>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.checkData()}>Speichern</Button>
                </Grid>
            </Grid>
        );
    }
}

export default DefaultNews

import React, {Component} from 'react';
import moment from "moment";
import "moment/locale/de";
import {Container} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {de} from 'date-fns/locale/de';
import {Outlet} from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import {ACCESS_TOKEN} from "../../constants";

import Header from "./header/Header";

import './Admin.scss';
import axios from "axios";
moment.locale('de')

class Admin extends Component {
    constructor(props){
        super(props);
        this.state = {
            authenticated: true,
            loading: false,
            locations: [],
            session: []
        };

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
        axios.post('/api/logout').then(res => {
            localStorage.removeItem(ACCESS_TOKEN);
            window.location = '/login';
        }).catch(function (error) {
            localStorage.removeItem(ACCESS_TOKEN);
            console.error('login', error);
        });
    }

    render(){
        if(this.state.loading){
            return <LoadingIndicator />
        }
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de} utils>
                <Container maxWidth="md" id="admin">
                    {/*{!this.state.authenticated ? <Redirect to='/admin/'/> : ''}*/}
                    <Header onLogout={this.handleLogout} roles={this.props.session?.roles}></Header>
                    <Outlet></Outlet>
                </Container>
            </LocalizationProvider>
        )
    }
}

export default Admin

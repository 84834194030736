import React, {Component} from 'react';

import './NewsTicker.scss';

import AppointmentNewsView from "./AppointmentNewsView";
import ComingSoonView from "./ComingSoonView";
import DefaultNewsView from "./DefaultNewsView";
import GraduationNewsView from "./GraduationNewsView";
import GreetingNewsView from "./GreetingNewsView";
import NewColleagueNewsView from "./NewColleagueNewsView";
import ProjectCompletionNewsView from "./ProjectCompletionNewsView";
import RapidTechTalkNewsView from "./RapidTechTalkNewsView";
import TeamTripNewsView from "./TeamTripNewsView";

import Axios from 'axios';
import Grid from '@mui/material/Grid';
import Slider from "react-slick";

class NewsTicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }

    componentDidMount() {
        this.getNews();
        //keep news up to date
        this.interval = setInterval(() => {
            this.getNews();
        }, 1000 * 60 * 5);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getNews() {
        const me = this;
        Axios.get('/api/news/slider').then(function (res) {
            const news = res.data;
            me.setState({
                news
            });
        }).catch(function (error) {
            console.error(error);
        });
    }

    getLogo() {
        switch (this.props.locationLabel) {
            case 'SCHEIDT':
            default:
                return <div className='logo n4news' />
            case 'SAARBRUECKEN':
                return <div className='logo plansoftware' />
        }
    }

    render() {
        const settings = {
            arrows: false,
            dots: false,
            responsive: [0,600,960,1280,1920],
            autoplay: true,
            autoplaySpeed: 20000,
            infinite: this.state.news?.length > 1,
        };
        return (
            <Grid item id="news-ticker" xs={12} lg={9}>
                {(() => {
                    if (this.state.news?.length > 0) {
                        return <div className="header">{this.getLogo()}</div>
                    }
                })()}

                {this.state.news && this.state.news.length > 0 ?
                <Slider {...settings}>
                    {this.state.news?.map( news => {
                        switch(news.newsType) {
                            case 'DEFAULT':
                                return <DefaultNewsView key={news.id} defaultNews={news}/>;
                            case 'GRADUATION':
                                return <GraduationNewsView key={news.id} graduationNews={news}/>;
                            case 'GREETING':
                                return <GreetingNewsView key={news.id} greetingNews={news}/>;
                            case 'NEW_COLLEAGUE':
                                return <NewColleagueNewsView key={news.id} newColleagueNews={news}/>;
                            case 'PROJECT_COMPLETION':
                                return <ProjectCompletionNewsView key={news.id} projectCompletionNews={news}/>;
                            case 'RAPID_TECH_TALK':
                                return <RapidTechTalkNewsView key={news.id} rapidTechTalkNews={news}/>;
                            case 'APPOINTMENT':
                                return <AppointmentNewsView key={news.id} appointmentNews={news}/>;
                            case 'TEAM_TRIP':
                                return <TeamTripNewsView key={news.id} teamTripsNews={news}/>;
                            default:
                                return <ComingSoonView locationLabel={this.props.locationLabel}></ComingSoonView>
                        }
                    })}
                </Slider>
                :
                <ComingSoonView locationLabel={this.props.locationLabel}></ComingSoonView>
                }
            </Grid>
        );
    }
}

export default NewsTicker
